<template>
    <div class="container">
        <div class="mx-auto max-w-960 pt-4">
            <h1 class="text-black mr-2 mb-4 text-xl lg:text-2xl text-center">
                Survey Confirmation
            </h1>
            <Loader v-if="loading" class="w-12 h-12" />
            <div v-show="!loading" ref="survey-confirmation-form-container"></div>

            <v-form
                v-if="isUserNominatedContact"
                ref="form"
                v-model="isValid"
                @submit.prevent="surveyConfirmationSubmitHandler"
            >
                <div class="surveyConfirmationCheckbox">
                    <h4 class="font-display font-semibold pt-5">Confirmation and acknowledgement</h4>
                    <v-checkbox
                        v-model="acknowledged"
                        :ripple="false"
                        color="primary"
                        :rules="[required]"
                    >
                        <template v-slot:label>
                            <div>
                                {{`I, ${userName}`}}
                                <br />
                                confirm that I am authorised to act on behalf of the practice
                                <br />
                                acknowledge receipt of the information provided for the scheduled accreditation survey visit
                                <br />
                                agree that this schedule is provided as a guide only and may be varied in response to findings on the day of the survey visit including requiring additional interviews with other doctors and staff 
                                <br />
                                agree that the practice shall provide the surveyor team with a private workspace and desktop computer with fixed internet or WiFi access.
                            </div>
                        </template>
                    </v-checkbox>
                </div>

                <div class="mt-4 w-full">
                    <v-btn
                        type="submit"
                        class="m-auto block"
                        color="primary"
                        depressed
                        :loading="submitting"
                    >Submit Confirmation</v-btn>
                </div>
            </v-form>
            <div
                v-else
                class="w-full py-3 px-4 mt-2 bg-red-light rounded flex justify-between items-center"
            >
                <div class="text-sm text-red-dark">
                    <fa-icon icon="exclamation-triangle" class="text-red mr-1"></fa-icon>
                    Only a nominated contact can sign and submit this survey confirmation.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loader from '@/assets/loader.svg'
import GET_PRACTICE from '@/graphql/queries/getPractice.gql'
import camelcaseKeys from 'camelcase-keys'
import {FormRules} from '@/components/shared/mixins/formMixins'
import {AuthMixin} from '@/components/shared/mixins/authHelpers'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import {validValue, onlyUnique} from '@/utils/array.helper'

export default {
    name: 'SurveyConfirmation',
    data() {
        return {
            practiceId: this.$route.params.id,
            loading: true,
            surveyConfirmationFormHtml: null,
            isValid: false,
            acknowledged: false,
            wifiAccess: '',
            submitting: false
        }
    },
    components: {
        Loader
    },
    mixins: [FormRules, AuthMixin, MessageDialog],
    apollo: {
        practice: {
            query: GET_PRACTICE,
            variables() {
                return {
                    id: this.practiceId
                }
            },
            skip() {
                return !this.practiceId
            },
            update({practice}) {
                if (!practice) return practice

                practice = camelcaseKeys(practice || {}, {deep: true})

                practice.accreditation = Array.isArray(practice.accreditations)
                    ? practice.accreditations[0]
                    : null
                practice.visit = Array.isArray(practice.accreditation?.visits)
                    ? practice.accreditation.visits[0]
                    : null
                practice.hasSurveyConfirmationDue =
                    practice.visit?.confirmationFormStatus === 'SENT'

                return practice
            }
        }
    },
    methods: {
        loadSurveyConfirmationForm() {
            let innerHtml = ''
            this.$http
                .get(`web.surveyvisit/${this.surveyVisitId}/get_confirmation_template/`)
                .then(({data}) => {
                    innerHtml = data
                })
                .catch(() => {
                    innerHtml = ''
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                    const container = this.$refs['survey-confirmation-form-container']
                    container.innerHTML = ''
                    if (innerHtml) {
                        container.attachShadow({mode: 'open'})
                        container.shadowRoot.innerHTML = innerHtml
                    }
                })
        },
        validate() {
            this.isValid = this.$refs.form.validate()
            return this.isValid
        },
        surveyConfirmationSubmitHandler() {
            if (this.validate()) this.confirmSurvey()
        },
        confirmSurvey() {
            this.submitting = true
            this.$http
                .post(`web.surveyvisit/${this.surveyVisitId}/sign_confirmation_form/`)
                .then(() => {
                    this.type = 'success'
                    this.message = 'Successfully submitted the survey confirmation form.'
                    return this.refreshPracticeAndAccreditation()
                })
                .catch(e => {
                    console.log(e)
                    this.type = 'error'
                    this.message = 'Failed to submit the survey confirmation form.'
                })
                .finally(() => {
                    this.submitting = false
                    this.showMessage({duration: 5000})
                })
        },
        refreshPracticeAndAccreditation() {
            const promises = [this.$apollo.queries.practice.refetch()]
            if (this.$apollo.queries.accreditationData)
                promises.push(this.$apollo.queries.accreditationData.refetch())

            return Promise.all(promises).then(() => {
                this.$router.push({name: 'Staff', params: {id: this.practiceId}})
            })
        }
    },
    computed: {
        nominatedContacts() {
            if (!this.practice?.accreditation) return []

            const accreditation = this.practice?.accreditation
            return [
                accreditation.nominatedAccreditationContact,
                accreditation.nominatedGpContact,
                accreditation.nominatedNurseContact,
                accreditation.nominatedSupportContact
            ].filter(contact => contact)
        },
        nominatedContactEmails() {
            return this.nominatedContacts
                .map(({email}) => email)
                .filter(validValue)
                .filter(onlyUnique)
        },
        nominatedContactUserIds() {
            return this.nominatedContacts
                .map(({user}) => user?.id)
                .filter(validValue)
                .filter(onlyUnique)
        },
        isUserNominatedContact() {
            return (
                this.nominatedContactUserIds.includes(this.userId) ||
                this.nominatedContactEmails.includes(this.userEmail)
            )
        },
        surveyVisitId() {
            return this.practice?.visit?.id
        }
    },
    watch: {
        practice() {
            if (!this.practice) {
                this.$router.push({name: 'PracticeList'})
                return
            }

            if (!this.submitting && this.practice && !this.practice.hasSurveyConfirmationDue)
                this.$router.push({name: 'Staff', params: {id: this.practiceId}})
        },
        surveyVisitId() {
            if (!this.surveyVisitId) return

            this.loadSurveyConfirmationForm()
        }
    }
}
</script>
